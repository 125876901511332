import * as React from 'react'

import SimpleReactLightbox from 'simple-react-lightbox'

import {} from '../components/sobhaVilla1Comp/heroArea.module.css'
import NavHeader from '../components/NavHeader'
import HeroArea from '../components/crest/heroArea'
import Connectivity from '../components/crest/connectivities'
import Eminity from '../components/crest/eminity'
import Plan from '../components/crest/plan'
import Footer from '../components/crest/footer'
import StickyForm from '../components/crest/stickyForm'
import PrivatePool from '../images/the-crest.png'

import {StaticImage} from 'gatsby-plugin-image'
import SEO from '../components/App/SEO'
import {siteUrl} from '../components/_utils'
import {useEffect, useState} from 'react'
import {getCountry} from '../action'
import {useScrollTracker} from 'react-scroll-tracker'
import ReactGA from 'react-ga'
import {contentDiv} from '../styles/sobha.module.css'
import StickyBox from 'react-sticky-box'
import ModalPage from "../components/modalPage1";

const shortNumber = require('short-number')

let dollarAmount = 265450

const Crest = ({data}) => {

    const [countryISO, setCountryISO] = useState('ae')
    const [short, setShort] = useState(shortNumber(dollarAmount))
    const [symbol, setSymbol] = useState('$')
    const [downloadType, setDownloadFormType] = useState(null)

    useEffect(() => {
        getCountry({amount: dollarAmount}).then((data) => {
            if (data.short) {
                setShort(data.short)
            }
            if (data.symbol) {
                setSymbol(data.symbol)
            }
            if (data.iso) {
                setCountryISO(data.iso.toLowerCase())
            }
        })
    }, [])

    if (typeof window !== 'undefined') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useScrollTracker([10, 15, 25, 50, 75, 100], ({scrollY}) => {
            ReactGA.event({
                category: 'scroll',
                action: scrollY,
            })
        })
    }

    const logoComp = (
        <StaticImage
            className="w-32 md:w-56 lg:w-56  align-middle"
            placeholder="tracedSVG"
            src={'../images/shobhaLand/sobha-hartland-right.png'}
            alt={'Sobha Hartland'}
        />
    )

    const titleParams = {
        title: `The Crest | Sobha Harland | ${siteUrl}`,
        ogTitle: `The Crest | Sobha Harland | ${siteUrl}`,

        description: 'Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences',
        ogDescription: 'Where Escaping is staying | Enjoy the perks of living by a crystal lagoon in central Dubai, Caribbean-style crystal lagoon facing residences',
        keywords: '5 YEAR PAYMENT PLAN',

        secureImageUrl: `${PrivatePool}`,
        ogImage: `${PrivatePool}`,
        ogImageAlt: 'The Crest',

        orUrl: `${siteUrl}crest/`,
        pageLink: `${siteUrl}crest/`,
        canonicalRef: `${siteUrl}crest/`,
    }

    return (
        <SimpleReactLightbox>
            <SEO data={titleParams}/>
            <NavHeader logoComp={logoComp}/>

            <ModalPage downloadType={downloadType}
                       onClose={() => {
                           setDownloadFormType(null);
                       }
                       }
                       formName={'crest'} countryISO={countryISO}/>

            <HeroArea
                countryISO={countryISO}
                onFormOpen={(v) => {
                    setDownloadFormType(v);
                }}
                short={short}
                symbol={symbol}/>

            <div className={`${contentDiv}`}>
                <div className="grid grid-cols-12 gap-1">
                    <div className="bg-red col-span-12 lg:col-span-8 lg:pr-16">
                        <Connectivity onFormOpen={(v) => {
                            setDownloadFormType(v);
                        }}/>
                        <Eminity onFormOpen={(v) => {
                            setDownloadFormType(v);
                        }}/>
                    </div>
                    <div className="col-span-4 hidden lg:block">
                        <StickyBox offsetTop={20} offsetBottom={20}>
                            <StickyForm onFormOpen={(v) => {
                                setDownloadFormType(v);
                            }} countryISO={countryISO}/>
                        </StickyBox>
                    </div>
                </div>
            </div>
            <Plan/>
            <Footer/>
        </SimpleReactLightbox>
    )
}

export default Crest
