import * as React from 'react'
import {MdArrowBackIosNew, MdArrowForwardIos} from 'react-icons/md'

import {StaticImage} from 'gatsby-plugin-image'
import Slider from 'react-slick'
import {speratorLine, titleHeading} from '../fiveJBR/form.module.css'

const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 500,
    arrows: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1008,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        }],
}

const FloorPlan = (props) => {
    return <div id={'fplan'} className={'text-white '}>
        <div className={'bg-black'}>
            <h2 id={'floorplan'} className={'font-bold text-lg pb-0 sm:text-2xl m-auto mb-0 pt-1 '}>Floor Plans</h2>
            <div className={'mb-8'}>
                <Slider {...settings}>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/1BA-TypeC.png'}
                            alt={'1 Bedroom Apartment Type C'}
                        />
                    </div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/1BA-TypeA1.png'}
                            alt={'1 Bedroom Apartment Type A1'}
                        />
                    </div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/1BA-TypeA.png'}
                            alt={'1 Bedroom Apartment Type A'}
                        />
                    </div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/1BA-TypeC1.png'}
                            alt={'Azizi '}
                        /></div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/2BA-TypeF.png'}
                            alt={'Azizi '}
                        /></div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/2BA-TypeE.png'}
                            alt={'Azizi '}
                        /></div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/2BA-TypeC.png'}
                            alt={'Azizi '}
                        /></div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/2BA-TypeG.png'}
                            alt={'Azizi '}
                        /></div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/2BA-TypeD.png'}
                            alt={'Azizi '}
                        /></div>
                    <div className={'p-4'}>
                        <StaticImage
                            src={'../../images/crest/2BA-TypeB.png'}
                            alt={'Azizi '}
                        /></div>
                </Slider>
            </div>
        </div>
        <div className="text-center mt-8">
            <button
                style={{flex: 1, alignContent: 'center'}}
                onClick={() => {
                    if (props.onFormOpen) {
                        props.onFormOpen('floor')
                    }
                    document.getElementById('downloadbrochure').style.display = 'block'
                }}
                className={'rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}
                type="submit">
                <span>Download Floor Plans </span>
            </button>
        </div>
        <p className={`${speratorLine}`}></p>
    </div>
}

const Eminity = (props) => {

    return (<>
        <div className={`container`}>

            <FloorPlan {...props}/>


            <div>
                <h2 className={`font-bold text-lg pb-0 sm:text-2xl m-auto mt-4 ${titleHeading}`}>Location in the Master
                    Plan</h2>
                <p className={'text-gray-500 mb-6'}> Located in the epicentre of the city with major landmarks just
                    minutes
                    away.
                </p>
                <div className={'pb-6 sm:pb-8'}>
                    <StaticImage
                        src={'../../images/crest/reachable.png'}
                        alt={'LOCATION IN THE MASTER PLAN'}/>
                </div>
            </div>

        </div>
    </>)
}

export default Eminity
