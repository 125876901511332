import * as React from 'react'
import { useRef } from 'react'
import useIntersectionObserver from '@react-hook/intersection-observer'

import { StaticImage } from 'gatsby-plugin-image'
import { BsCheckCircle } from 'react-icons/bs'
import {
  crestList,
  videoContainer,
  gridBox,
  gridBox2,
  firstBlock,
  area,
  price,
  downloadBtn,
} from './heroArea.module.css'
import { loaderText, imageBox, speratorLine, titleHeading, bulletPoint } from '../fiveJBR/form.module.css'
import { ImSpinner, ImDownload, ImFilePdf } from 'react-icons/im'
import CR2 from '../../images/crest/crystal-lagoon/2.png'
import CR3 from '../../images/crest/crystal-lagoon/3.png'
import CR4 from '../../images/crest/crystal-lagoon/4.png'

import GR1 from '../../images/crest/graderns/1.png'
import GR2 from '../../images/crest/graderns/2.png'
import GR3 from '../../images/crest/graderns/3.png'
import { SRLWrapper } from 'simple-react-lightbox'

const PaymentPlan = () => {
  return <div className={'pb-0'}>

    <h2 id="paymentplan" className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-0`}>
      6 Years Payment Plan
    </h2>
    <p className={'text-gray-500 mb-6'}>Introducing 50:50 Payment Plan!</p>

    <div className="grid grid-cols-1 md:mb-4 md:grid-cols-2 lg:grid-cols-2 gap-4 sm:pb-0 pb-4">
      {/* <div className={`${gridBox}`}>
        <div className={`${firstBlock}`}>
          <h2>5 year</h2>
        </div>
        <div>
          <h4>Payment Plan</h4>
        </div>

      </div>*/}
      <div className={`${gridBox}`}>
        <div className={`${firstBlock}`}>
          <h2>50%</h2>


        </div>
        <div>
          <h4 className={'text-gray-700'}>
            Over 4 years
          </h4>
        </div>

      </div>
      <div className={`${gridBox}`}>
        <div className={`${firstBlock}`}>
          <h2>50%</h2>
        </div>
        <div>
          <h4 className={'text-gray-700'}>
            2 Years Post-Handover
          </h4>
        </div>
      </div>
    </div>
    <p className={'text-gray-700'}>* Offer valid till 31st Jan 2022.</p>


    {/* <div className={`container text-left border border-primary-lightBorder mb-4 p-4`}>
      <div>
        <h2
          className={'font-bold md:pb-2 pb-0 text-md font-lato  mb-2'}>5 Year Plan | 70% Over 4 Years
          | 30%
          at Completion 2025 as is..
        </h2>

        <ul className={`${crestList} leading-6`}>
          <li>
            <BsCheckCircle/>
            2021: 10% Dec
          </li>
          <li>
            <BsCheckCircle/>
            2022: 10% Mar, 10% Sep
          </li>
          <li>
            <BsCheckCircle/>
            2023: 10% Mar, 10% Sep
          </li>
          <li>
            <BsCheckCircle/>
            2024: 10% Mar, 10% Sep
          </li>
          <li>
            <BsCheckCircle/>
            2025: 30% on completion
          </li>
        </ul>


      </div>

    </div>*/}

  </div>
}

const Pricing = ({onFormOpen}) => {
  return <div>

    <h2 id="paymentplan" className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-0`}>
      1, 2 & 3 Bedroom Residential Apartments
    </h2>

    <p className={'hidden text-gray-500 mb-6'}>
      The Crest is a truly stunning development with contemporary design and ideal waterfront position.
    </p>

    <p className={'text-gray-700 mt-4 mb-6'}>The Crest offers a mix of one to three-bedroom apartments with
      spacious open
      plan layouts, full-height windows to maximize the amazing view, and interior fittings of superior quality. For
      single professionals and families, it is very much the place to be.
    </p>

    <div className="grid grid-cols-1 md:mb-4 md:grid-cols-3 lg:grid-cols-3 gap-4">

      <div className={`${gridBox2}`}>
        <div className={`${firstBlock}`}>
          <h2>1 Bedroom <small>with powder room + balcony</small></h2>
        </div>
        <div>
          <p className={`${area} text-gray-700`}>573 - 889 sqft.</p>
          {/* <p className={`${price}`}><small>Starting from</small> AED 1.1 Million* </p>*/}
        </div>
        {/*<button className={downloadBtn}>
          <ImFilePdf/>Download Brochure
        </button>*/}
      </div>

      <div className={`${gridBox2}`}>
        <div className={`${firstBlock}`}>
          <h2>2 Bedroom <small>with Maid Room + balcony</small></h2>
        </div>
        <div>
          <p className={`${area} text-gray-700`}>1270 - 1394 sqft.</p>
          {/*  <p className={`${price}`}><small>Starting from</small> AED 2.45 Million*</p>*/}
        </div>
        {/* <button className={downloadBtn}>
          <ImFilePdf/> Download Brochure
        </button>*/}
      </div>

      <div className={`${gridBox2}`}>
        <div className={`${firstBlock}`}>
          <h2>3 Bedroom <small>with Maid Room + balcony</small></h2>
        </div>
        <div>
          <p className={`${area} text-gray-700`}>1565 sqft.</p>
          {/*   <p className={`${price}`}><small>Starting from</small> AED 3.01 Million*</p>*/}
        </div>
        {/*<button className={downloadBtn}>
          <ImFilePdf/> Enquire
        </button>*/}
      </div>


    </div>
    <div className="text-center mt-8">
      <button
          style={{flex: 1, alignContent: 'center'}}
          onClick={() => {
            onFormOpen('payment')
            document.getElementById('downloadbrochure').style.display = 'block'
          }}
          className={'rounded  text-lg  bg-primary-darkGold py-3 px-4 text-white'}
          type="submit">
        {/*<span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>*/}
        <span>Download Payment Plans </span>
      </button>
    </div>


    {/* <div className={`container text-left border border-primary-lightBorder mb-4 p-4`}>
      <div>
        <h2
          className={'font-bold md:pb-2 pb-0 text-md font-lato  mb-2'}>5 Year Plan | 70% Over 4 Years
          | 30%
          at Completion 2025 as is..
        </h2>

        <ul className={`${crestList} leading-6`}>
          <li>
            <BsCheckCircle/>
            2021: 10% Dec
          </li>
          <li>
            <BsCheckCircle/>
            2022: 10% Mar, 10% Sep
          </li>
          <li>
            <BsCheckCircle/>
            2023: 10% Mar, 10% Sep
          </li>
          <li>
            <BsCheckCircle/>
            2024: 10% Mar, 10% Sep
          </li>
          <li>
            <BsCheckCircle/>
            2025: 30% on completion
          </li>
        </ul>


      </div>

    </div>*/}


  </div>
}

const LazyIframe = ({ url, title }) => {
  const containerRef = useRef()
  const lockRef = useRef(false)
  const { isIntersecting } = useIntersectionObserver(containerRef)
  if (isIntersecting) {
    lockRef.current = true
  }
  return (
    <div
      style={{
        // overflow: 'hidden',
        // paddingTop: '56.25%',
        // position: 'relative',
        width: '100%',
      }}
      ref={containerRef}
    >
      {lockRef.current && (
        <iframe
          title={title}
          style={{
            bottom: 0,
            height: '100%',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100%',
          }}
          src={url}
          frameborder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="allowfullscreen"
        ></iframe>
      )}
    </div>
  )
}


const Connectivity = (props) => {
  const [loading, setLoading] = React.useState(false)

  return (<>
    <div>

      <Pricing {...props}/>

      <p className={`${speratorLine}`}></p>

      <PaymentPlan/>

      <p className={`${speratorLine}`}></p>

      <div>

        <h2 className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-0`}>
          The Crest at Sobha Hartland
        </h2>

        <p className={'pb-0 m-auto text-gray-500 mb-6'}>
          Enjoy the perks of living by a crystal lagoon in central Dubai
        </p>

        {/*   <div className={`${imageBox} mb-4`}>
          <StaticImage
            src={'../../images/the-crest.png'}
            alt={'The Sobha Crest Building Waterfront'}
          />
        </div>*/}


        <div className={videoContainer}>
          <LazyIframe url={"https://www.youtube.com/embed/g93FGbfo3Sg"} title={"The Crest | Sobha Hartland"}/>
        </div>


        <p className={`${speratorLine}`}></p>
        <div>

          <div className={'col-span-2'}>
            <ul className={`${bulletPoint}`}>
              <li> The Crest Tower A has residences spread across 46 floors.</li>
              <li> The 3 towers have a series of stepped SKY GARDENS connecting them with each other.</li>
              <li> A host of AMENITIES spread across the podium level and the landscaped terraces; curated just for you.
              </li>
              <li> Residential living space that provides endless moments of EXPERIENCES.</li>
              <li> Inter-linked SKYWALK that brings all 3 towers of The Crest together.</li>
            </ul>
          </div>

        </div>

      </div>

      <p className={`${speratorLine}`}></p>


      {/*  <div className={videoContainer}>
        <LazyIframe url={"https://www.youtube.com/embed/g93FGbfo3Sg"} title={"The Crest | Sobha Hartland"} />
      </div>*/}

      <div className={'mt-4'}>
        <p className={'font-light text-lg pb-0 sm:text-xl m-auto mb-0 '}>
          Residences that provide endless Moments of experiences with no reason to step out.
        </p>
        {/* <StaticImage
          src={'../../images/crest/crest-features.png'}
          alt={'The Sobha Crest Apartments Features'}
        />*/}
      </div>

      {/*   <p className={`${speratorLine}`}></p>*/}


      <div className={'text-center pt-4 pb-6 sm:py-8'}>

        <div className={'pb-0 sm:pt-8'}>

          <SRLWrapper>
            <p className={'font-light pb-0 m-auto mb-4 '}>
              Where you and your loved ones create memories together, by the banks of a pristine crystal
              lagoon
            </p>

            <div className={'grid grid-cols-1 sm:grid-cols-3 gap-4'}>

              <div className={`${imageBox} `}>
                <a href={CR2}>
                  <StaticImage
                    srl_gallery_image="true"
                    src={'../../images/crest/crystal-lagoon/2.png'}
                    alt={'Located on the banks of a Crystal Lagoon'}
                  />
                </a>
                <h4>Located on the banks of a Crystal Lagoon</h4>
              </div>

              <div className={`${imageBox} `}>
                <a href={CR3}>
                  <StaticImage
                    srl_gallery_image="true"
                    src={'../../images/crest/crystal-lagoon/3.png'}
                    alt={'Views of Ras Al Khor Wildlife Sanctuary'}
                  />
                </a>
                <h4>Views of Ras Al Khor Wildlife Sanctuary</h4>
              </div>

              <div className={`${imageBox} `}>
                <a href={CR4}>
                  <StaticImage
                    srl_gallery_image="true"
                    src={'../../images/crest/crystal-lagoon/4.png'}
                    alt={'3 inter-connected expansive towers'}
                  />
                </a>
                <h4>3 inter-connected expansive towers</h4>
              </div>

            </div>

            <p className={'font-light pb-0 m-auto mb-4 pt-8'}>
              Residential Towers with inter-linked Sky Gardens and other full of Amenities
            </p>

            <div className={'grid grid-cols-1 sm:grid-cols-3 gap-4'}>

              <div className={`${imageBox} `}>
                <a href={GR1}>
                  <StaticImage
                    srl_gallery_image="true"
                    src={'../../images/crest/graderns/1.png'}
                    alt={'Landscaped Terraces at different levels'}
                  />
                </a>
                <h4>Landscaped Terraces</h4>
              </div>

              <div className={`${imageBox} `}>
                <a href={GR2}>
                  <StaticImage
                    srl_gallery_image="true"
                    src={'../../images/crest/graderns/2.png'}
                    alt={'Inter-linked Sky Gardens'}
                  />
                </a>
                <h4>Inter-linked Sky Gardens</h4>
              </div>

              <div className={`${imageBox} `}>
                <a href={GR3}>
                  <StaticImage
                    srl_gallery_image="true"
                    src={'../../images/crest/graderns/3.png'}
                    alt={'Yoga Studio'}
                  />
                </a>
                <h4>Yoga Studio</h4>
              </div>

            </div>

          </SRLWrapper>
        </div>

        <p className={`${speratorLine} `}></p>


        <div className={'mt-0'}>
          <h2 className={`${titleHeading} font-bold text-lg sm:text-2xl m-auto mb-0 pt-6`}>2 International Schools</h2>

          <p className={'pb-0 m-auto text-gray-500 mb-6'}>
            Your luxury residence is in close proximity to two coveted educational institutions,<br/> <b>Hartland
            International School</b> and <b>North London Collegiate School</b>.
          </p>

          <div className="grid mt-4 grid-cols-1 md:mb-4 md:grid-cols-2 lg:grid-cols-2 gap-4">

            <div className={imageBox}>
              <StaticImage
                src={'../../images/crest/school2.png'}
                alt={'Hartland International School'}/>
              <h3>Hartland International School</h3>
              <p>
                A premium British education <br/>
                2 Mins away
              </p>

            </div>

            <div className={imageBox}>
              <StaticImage
                src={'../../images/crest/school1.png'}
                alt={'North London Collegiate School'}/>
              <h3>NORTH LONDON Collegiate school</h3>
              <p>
                an International Baccalaureate (IB) education<br/>
                2 Mins away
              </p>
            </div>


          </div>

        </div>

        <p className={`${speratorLine} `}></p>


        {/*<div className={'mt-2'}>
          <button disabled={loading}
                  style={{flex: 1, alignContent: 'center'}}
                  className={'rounded  text-lg  bg-primary-darkGreen py-3 px-4 text-white'}
                  type="submit">
            <span className={loaderText}>{loading && <ImSpinner className={'icon-spin '}/>}</span>
            <span>Yes, I am Interested</span>
          </button>

        </div>*/}

      </div>




    </div>
  </>)
}

export default Connectivity
