import {
  firstGrid, gridText, minTextClass,
} from './heroArea.module.css'
import * as React from 'react'
import image1 from '../../images/crest/internatinalSchool.png'
import image2 from '../../images/crest/burjKhalifa.png'
import image3 from '../../images/crest/dubaiMall.png'
import image4 from '../../images/crest/racecourse.png'
import image5 from '../../images/crest/sanctuary.png'
import image6 from '../../images/crest/countryClub.png'
import image7 from '../../images/crest/dubaiOpera.png'
import image8 from '../../images/crest/difc.png'
import image9 from '../../images/crest/airport.png'
import image10 from '../../images/crest/marina.png'
import image11 from '../../images/crest/palm.png'
import image12 from '../../images/crest/golfClub.png'
import { StaticImage } from 'gatsby-plugin-image'

const Plans = () => {
  return (
    <>
      <div>
        <StaticImage
          src={'../../images/crest/footer2.png'}
          alt={'photo'}/>
      </div>
      <div className={'bg-black py-6 pt-4'}>


        <div className={'container pt-8'}>

          <div
            className={`grid grid-cols-2  sm:grid-cols-3 md:grid-cols-6 gap-0 sm:gap-y-8 sm:gap-x-12 ${firstGrid}`}>
            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image1}/></div>
              <p className={gridText}>2 MINUTES</p>
              <p className={minTextClass}>INTERNATIONAL SCHOOLS</p>
            </div>
            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image2}/></div>
              <p className={gridText}>5 MINUTES</p>

              <p className={minTextClass}>BURJ KHALIFA</p>
            </div>
            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image3}/></div>
              <p className={gridText}>5 MINUTES</p>
              <p className={minTextClass}>DUBAI MALL</p>
            </div>

            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image4}/></div>
              <p className={gridText}>5 MINUTES</p>
              <p className={minTextClass}>MEYDAN RACECOURSE</p>
            </div>


            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image5}/></div>
              <p className={gridText}>5 MINUTES</p>
              <p className={minTextClass}>RAS AL KHOR WILDLIFE <br/>SANCTUARY</p>


            </div>

            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image6}/></div>
              <p className={gridText}>5 MINUTES</p>
              <p className={minTextClass}>COUNTRY CLUB</p>


            </div>


            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image7}/></div>
              <p className={gridText}>10 MINUTES</p>
              <p className={minTextClass}>DUBAI OPERA</p>

            </div>


            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image8}/></div>
              <p className={gridText}>20 MINUTES</p>
              <p className={minTextClass}>DIFC</p>


            </div>


            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image9}/></div>
              <p className={gridText}>20 MINUTES</p>
              <p className={minTextClass}>INTERNATIONAL AIRPORT</p>

            </div>

            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image10}/></div>
              <p className={gridText}>20 MINUTES</p>
              <p className={minTextClass}>DUBAI MARINA</p>


            </div>
            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image11}/></div>
              <p className={gridText}>20 MINUTES</p>
              <p className={minTextClass}>PALM JUMEIRAH</p>


            </div>
            <div className={'flex flex-col items-center'}>
              <div className={'mb-2'}><img alt={'c'}
                                           src={image12}/></div>
              <p className={gridText}>20 MINUTES</p>
              <p className={minTextClass}>EMIRATES GOLF CLUB</p>

            </div>


          </div>
        </div>


      </div>
    </>
  )
}

export default Plans
