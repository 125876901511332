// extracted by mini-css-extract-plugin
export var mainSection = "heroArea-module--mainSection--1ue1O";
export var headerSection = "heroArea-module--headerSection--32gdw";
export var topSection = "heroArea-module--topSection--TgzcK";
export var bottomUnderLine = "heroArea-module--bottomUnderLine--kQdz4";
export var footerList = "heroArea-module--footerList--2RYFg";
export var heroText = "heroArea-module--heroText--3cm0B";
export var crestList = "heroArea-module--crestList--2AFy6";
export var sobhaHeading = "heroArea-module--sobhaHeading--2NjmD";
export var sliderFooterContainer = "heroArea-module--sliderFooterContainer--2EYUl";
export var sliderFooter = "heroArea-module--sliderFooter--3IOYl";
export var videoContainer = "heroArea-module--videoContainer--3aV-A";
export var gridBox = "heroArea-module--gridBox--1bsRy";
export var firstBlock = "heroArea-module--firstBlock--1VM9u";
export var gridBox2 = "heroArea-module--gridBox2--1jX-X";
export var area = "heroArea-module--area--3YFxD";
export var price = "heroArea-module--price--3xqgD";
export var downloadBtn = "heroArea-module--downloadBtn--hUvmY";
export var firstGrid = "heroArea-module--firstGrid--3hdh3";
export var gridText = "heroArea-module--gridText--3NUJ1";
export var minTextClass = "heroArea-module--minTextClass--3gqKK";
export var addressBlock = "heroArea-module--addressBlock--oZL16";
export var formBox = "heroArea-module--formBox--Tc-GY";